<template>
  <pv-inline-message class="center" severity="info">システム管理者ログイン</pv-inline-message>
  <form @submit.prevent="login">
    <div v-if="!noClient" class="card">
      <pv-input label="メールアドレス" type="text" v-model="info.email" :error="error.email" float full required />
      <pv-password label="パスワード" v-model="info.password" :error="error.password" float full />
      <pv-button class="mt-3" type="submit" label="ログイン" />
      <pv-button class="p-button-text p-button-secondary" label="パスワードを忘れた方はこちら" @click="gotoReminder" />
    </div>
  </form>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import http from "axios";

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const info = ref({});
    const error = ref({ email: "", password: "" });

    if (store.state.admin) {
      router.push('/label/');
    }

    const noClient = ref(false);
    http.get('/api/').catch(() => {
      noClient.value = true;
    });

    async function login() {
      let r = await http.post(`/api/admin/login`, info.value);
      if (r.data.error) {
        error.value.password = "メールアドレスかパスワードが間違っています";
        return;
      }

      router.push('/label/');
    }

    function gotoReminder() {
      router.push("/admin/password/mail/");
    }

    return { info, error, noClient, login, gotoReminder };
  },
}
</script>